<template>
  <div>
    <!-- Filters -->
    <basic-filters
      @basic-filters-search="search"
      @basic-filters-changed="set_filters"
      :allowed_filters="allowed_filters"
      :suburb_set_to_use="suburb_set_to_use"
      page="favourite_listings"
    />
    <listings-sidebar :sidebar="sidebar" :listing="sidebar_listing" @toggle_sidebar="(val) => (sidebar = val)" />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row class="d-flex align-items-baseline justify-content-center">
          <!-- Per Page -->
          <b-col cols="12" md="3" class="d-flex align-items-baseline justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select
              v-model="options.perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <!-- <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="options.filter" class="d-inline-block mr-1" placeholder="Search Current Page" />
            </div> -->
          </b-col>
          <b-col cols="12" md="3">
            <div class="d-flex align-items-baseline justify-content-end">
              <b-button variant="flat-primary" @click="initialize()">
                <feather-icon icon="RefreshCwIcon" class="mr-25" />
                <span class="align-middle">Refresh</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="listingtable"
        primary-key="listing_id"
        :items="listings"
        :fields="tableColumns"
        :sort-by.sync="options.sortBy"
        :sort-desc.sync="options.sortDesc"
        :current-page.sync="options.currentPage"
        per-page="0"
        :filter="null"
        :api-url="null"
        :empty-text="busy ? 'Loading Data...' : 'No matching records found'"
        show-empty
        :busy="busy"
        @row-clicked="show_listing_sidebar"
        class="position-relative"
        sticky-header="80vh"
        responsive
        hover
        head-variant="light"
        no-sort-reset
        dense
        small
        striped
      >
        <!-- Column: address -->
        <template #cell(address)="data">
          <div
            class="d-flex align-items-baseline justify-content-between"
            style="padding: 10px 20px"
            :class="{ old_listing: is_old(data.item.input_date), is_viewed: data.item.viewed }"
          >
            <div class="pr-1">{{ data.item.address }}</div>
            <span class="small-text">{{ updated_status(data.item) }}</span>
          </div>
        </template>

        <!-- Column: zone -->
        <template #cell(city_plan_)="data">
          <div class="d-flex align-items-baseline justify-content-center">
            <div class="pr-1">
              {{
                !is_fractional_noncommercial(data.item) && data.item.property.length && permissions.property_data.includes("zone")
                  ? data.item.property[0].zone.replace(/QPP-/g, "")
                  : "-"
              }}
            </div>
          </div>
        </template>

        <!-- Column: frontage -->
        <template #cell(max_frontage)="data">
          <div class="d-flex align-items-baseline justify-content-center">
            <div class="pr-1">
              {{
                !is_fractional_noncommercial(data.item) &&
                data.item.property.length &&
                data.item.property[0].max_frontage &&
                permissions.property_data.includes("frontage")
                  ? data.item.property[0].max_frontage.toFixed(1)
                  : "-"
              }}
            </div>
          </div>
        </template>

        <!-- Column: fall -->
        <template #cell(fall)="data">
          <div class="d-flex align-items-baseline justify-content-center">
            <div class="pr-1">
              {{
                data.item.property.length && data.item.property[0].fall && permissions.property_data.includes("slope")
                  ? parseFloat(data.item.property[0].fall).toFixed(1)
                  : "-"
              }}
            </div>
          </div>
        </template>

        <!-- Column: address -->
        <!-- <template #cell(max_frontage)="data">
          <div class="d-flex align-items-baseline justify-content-around">
            <div class="">{{ data.item.property && data.item.property.length ? data.item.property[0].max_frontage : "" }}</div>
            <b-badge v-if="data.item.property && data.item.property.length && data.item.property[0].frontages.length > 1" pill variant="light-primary">
              {{ data.item.property[0].frontages.length }}
            </b-badge>
          </div>
        </template> -->

        <!-- Column: Status -->
        <template #cell(links)="data">
          <div class="d-flex align-items-baseline justify-content-start">
            <!-- v-b-tooltip:hover.d100.top.v-primary.title="'Google Search'" -->
            <font-awesome-icon
              @click.stop="open_link('https://www.google.com/search?q=' + data.item.address)"
              v-b-tooltip:hover.v-primary="{
                customClass: '',
                variant: 'info',
                title: 'Google Search',
                placement: 'topleft',
                trigger: 'hover',
              }"
              color="#1f5a94"
              class="link-icons"
              icon="fa-brands fa-google"
            />
            <font-awesome-icon
              v-b-tooltip:hover.d100.top.v-primary.title="'Visit Source'"
              color="#1f5a94"
              @click.stop="open_link(data.item.listing_web_address)"
              class="link-icons"
              icon="fa-solid fa-arrow-up-right-from-square"
            />
            <font-awesome-icon
              @click="show_listing_sidebar(data.item)"
              class="link-icons"
              icon="fa-solid fa-circle-info"
              v-b-tooltip:hover.d100.top.v-primary.title="property_data_icon_tooltip(data.item.property.length)"
              :color="data.item.property.length ? '#1F5A94' : 'grey'"
            />

            <feather-icon
              icon="CheckCircleIcon"
              size="16"
              class="link-icons"
              :color="domain_icon_color(data.item.domain_data)"
              v-b-tooltip:click.d100.top.v-primary.title="domain_icon_tooltip(data.item.domain_data)"
            />
            <feather-icon
              @click.stop="favourite_listing(data.item.listing_id)"
              icon="HeartIcon"
              size="16"
              class="link-icons"
              :color="'red'"
              :fill="data.item.favourite.length ? 'red' : 'white'"
              v-b-tooltip:click.d100.top.v-primary.title="'Add to favoutites'"
            />
            <!--  EyeOffIcon-->
            <!-- <feather-icon
              @click.stop="hide_listing(data.item.listing_id)"
              icon="EyeIcon"
              size="16"
              class="link-icons"
              :color="'primary'"
              v-b-tooltip:click.d100.top.v-primary.title="'Shod / hide listing'"
            /> -->
          </div>
        </template>

        <!-- Column: Actions -->
        <!-- <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>
            <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }" disabled>
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Save Listing</span>
            </b-dropdown-item>
          </b-dropdown>
        </template> -->
      </b-table>

      <!-- pagination section -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" md="4" class="d-flex align-items-center justify-content-start">
            <span class="text-muted">
              Showing {{ (options.currentPage - 1) * options.perPage }} to {{ (options.currentPage - 1) * options.perPage + options.perPage }} of
              {{ total_listings }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" md="8" class="d-flex align-items-center justify-content-start">
            <b-pagination
              v-model="options.currentPage"
              :per-page="options.perPage"
              :total-rows="total_listings"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import moment from "moment";
import { BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BLink, BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip } from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ListingsSidebar from "./components/ListingsSidebar.vue";
import BasicFilters from "./components/BasicFilters.vue";

export default {
  name: "SavedListings",
  components: {
    BasicFilters,
    ListingsSidebar,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BMedia,
    vSelect,
    VBTooltip,
    ToastificationContent,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      listings: [],
      loading: false,
      tableColumns: [
        {
          key: "address",
          label: "Address",
          sortable: true,
          variant: "warning",
          stickyColumn: true,
          thStyle: { width: "500px" },
          tdClass: "listings-table-td-address",
          thClass: "'listings-table-td-address'",
        },
        // {
        //   key: "status",
        //   label: "Status",
        //   sortable: true,
        // },

        // {
        //   key: "property_type",
        //   label: "Type",
        //   sortable: true,
        // },
        // {
        //   key: "property_type_other",
        //   label: "Category",
        //   sortable: true,
        // },
        {
          key: "city_plan_",
          label: "Zone",
          sortable: false,
          formatter: (v, k, item) => (item.property.length ? item.property[0].zone.replace("QPP-", "") : ""),
          thStyle: { width: "8%" },
        },
        {
          key: "area",
          label: "Size",
          sortable: false,
          formatter: (v, k, item) => (item.property.length ? item.property[0].area.toFixed(1) : ""),
          thStyle: { width: "8%" },
        },
        {
          key: "max_frontage",
          label: "Frontage",
          sortable: true,
          formatter: (v, k, item) => (item.property && item.property.length && item.property[0].max_frontage ? item.property[0].max_frontage.toFixed(1) : ""),
          // thStyle: { width: "8%" },
        },
        ,
        {
          key: "fall",
          label: "Fall",
          sortable: false,
          formatter: (v, k, item) => (item.property.length && item.property[0].fall ? parseFloat(item.property[0].fall).toFixed(1) : ""),
          thStyle: { width: "8%" },
        },
        {
          key: "price",
          label: "Price",
          sortable: true,
          thStyle: { width: "15%" },
        },
        {
          key: "status_date",
          label: "Updated",
          sortable: true,
          thStyle: { width: "10%" },
        },

        {
          key: "links",
          label: "Actions",
          thStyle: { width: "10%", "text-align": "center" },
        },
        // "actions"
      ],

      // table options
      options: {
        currentPage: 1,
        perPage: 50,
        sortBy: "status_date",
        sortDesc: true,
        // filter: null,
        // apiUrl: null,
      },

      default_options: {
        currentPage: 1,
        perPage: 50,
        sortBy: "status_date",
        sortDesc: true,
        // filter: null,
        // apiUrl: null,
      },

      total_listings: 0,
      perPageOptions: [10, 50, 100, 150, 200, 250],
      // pagination: { from: 0, to: 0, of: 0 },
      busy: false,

      // sidebar fields
      sidebar: false,
      sidebar_listing: null,

      // filtrs options
      filters: [],
      allowed_filters: ["address", "suburb", "postcode", "property_type", "property_type_other", "city_plan_", "length", "area", "fall"],
      suburb_set_to_use: "bcc_suburbs",

      // highlighting
      three_months_ago: moment().subtract(3, "months").format("YYYY-MM-DD"),
      last_two_dates: [0, 1, 2].map((d) => moment().subtract(d, "days").format("YYYY-MM-DD")),
      permissions: null,
    };
  },

  watch: {
    options: {
      deep: true,
      async handler() {
        await this.load_listings();
      },
    },
  },
  async mounted() {
    try {
      this.initialize();
      this.permissions = await this.$store.dispatch("get_permissions");
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    async initialize() {
      try {
        // changing options would trigger load_listings() by the watcher
        this.options = Object.assign({}, this.default_options);
      } catch (error) {
        console.log(error);
      }
    },
    async load_listings() {
      try {
        this.busy = true;
        let res = await this.$axios.post(`${this.$API_BASE_LISTINGS}/getfavouritelistings`, { filters: this.filters, options: this.options });
        this.busy = false;
        // console.log(res.data);
        if (!res.data.success) throw new Error("listings could not be fetched");

        this.listings = res.data.listings;
        this.total_listings = res.data.count;
      } catch (error) {
        this.busy = false;
        console.log(error);
      }
    },
    async search(filters) {
      this.filters = filters;
      this.options.currentPage != 1 ? (this.options.currentPage = 1) : await this.load_listings();
      // await this.load_listings();
    },
    set_filters(filters) {
      this.filters = filters;
    },
    show_listing_sidebar(listing) {
      console.log(listing);
      this.sidebar_listing = listing;
      this.sidebar = true;
    },
    open_link(link) {
      window.open(link);
    },
    domain_icon_color(data) {
      if (data && Object.keys(data).length && data["last_checked"]) {
        if (data["status"].toLowerCase() == "onmarket") return "orange";
        if (data["status"].toLowerCase() == "offmarket") return "green";
      }
      return "grey";
    },
    domain_icon_tooltip(data) {
      if (data && Object.keys(data).length && data["last_checked"]) return `Domain Data Available - ${data["status"]}`;
      else return "Domain Data Not Available";
    },
    property_data_icon_tooltip(condition) {
      if (condition) return `Property Data Available`;
      else return "Property Data Not Available";
    },
    async favourite_listing(id) {
      try {
        this.busy = true;
        let res = await this.$axios.post(`${this.$API_BASE_LISTINGS}/updatefavourite`, { id });
        this.busy = false;
        if (!res.data.success) throw new Error("listings status could not be updated");

        const index = this.listings.findIndex((l) => l.listing_id == id);
        this.listings.splice(index, 1);
        this.total_listings--;
        // this.listings[index].favourite = res.data.added ? [{ listing_id: res.data.listing_id }] : [];
        this.showToast("info", "Info", `Favourites Updated - ${this.listings[index].address}`);
      } catch (error) {
        this.busy = false;
        console.log(error);
        this.showToast("danger", "Error", "Favourites could not be updated");
      }
    },
    showToast(variant = "info", title = "Info", text = "") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: "BellIcon",
          text,
          variant,
        },
      });
    },
    is_old(input_date) {
      return input_date && input_date < this.three_months_ago ? true : false;
    },
    updated_status(l) {
      let text = "";
      if (this.last_two_dates.includes(l.status_date)) {
        if (l.status_date == l.input_date) text = "(New)";
        else text = "(Updated)";
      }
      return text;
    },
    is_fractional_noncommercial(listing) {
      if (listing && listing.address.includes("/") && !/\bcommercial\b/gi.test(listing.property_type_other) && !/\bcommercial\b/gi.test(listing.property_type))
        return true;
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.link-icons {
  padding-right: 3px;
  cursor: pointer;
  font-size: 1em;
  font-weight: 800;
}
.small-text {
  font-size: 11px;
  font-weight: bold;
}
.old_listing {
  background-color: #ffd7d7;
}
.is_viewed {
  // background-color: #ffd7d7;
  // color: red;
  opacity: 0.5;
}
</style>
